import { Route, Routes, useRouteError } from 'react-router-dom';
import { Branding } from '../Components/Branding';
import { Business } from '../Components/Business';
import { Careers } from '../Components/Careers';
import { Experience } from '../Components/Experience';
import { Home } from '../Components/Home';

export function Router() {
  function ErrorBoundary() {
    let error = useRouteError();
    console.error(error);
    return <div>404 Error</div>;
  }
  return (
    <Routes>
      <Route path="/" element={<Home />} errorElement={<ErrorBoundary />} />
      <Route path="*" element={<Home />} errorElement={<ErrorBoundary />} />
      <Route
        path="/branding"
        element={<Branding />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        path="/experience"
        element={<Experience />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        path="/business"
        element={<Business />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        path="/careers"
        element={<Careers />}
        errorElement={<ErrorBoundary />}
      />

      <Route />
    </Routes>
  );
}
