import { useEffect, useState } from 'react';
import exData from '../../Data/experience.json';
import coreData from '../../Data/core_experience.json';
import { FaArrowLeftLong, FaArrowRightLong } from 'react-icons/fa6';

export interface InfiniteLoopPropType {
  // children: React.ReactNode;
  onHoverStop?: boolean;
}

export function Experience() {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(0);
  const [datalist, setDatalist] = useState<any[]>([]);
  const [corelist, setCorelist] = useState<any[]>([]);
  const [pagingDataSet, setPagingDataSet] = useState<any[]>([]);
  const [subject, setSubject] = useState<string>('');
  const [since, setSince] = useState<string>('');
  let indexArray = Array.from({ length: 10 }, (item, index) => {
    return index;
  });
  let pageIndex: any[] =
    page === 1 ? indexArray : indexArray.map((item) => item + (page - 1) * 10);

  useEffect(() => {
    async function getData() {
      return await new Promise((resolve, reject) => {
        resolve(exData.exData);
        reject(new Error('No data'));
      });
    }
    if (isLoading) {
      if (since !== '') {
        getData()
          .then((resolveData: any) => {
            const refinedData = resolveData.filter((el: any) => {
              return el.since === parseInt(since);
            });
            if (subject === '') {
              setDatalist(refinedData);
            } else {
              const refinedData2 = refinedData.filter((el: any) => {
                return el.subject === subject;
              });
              setDatalist(refinedData2);
            }
          })
          .catch((err: Error) => console.error(err))
          .finally(() => {
            setLoading(false);
            setPage(1);
          });
      } else {
        getData()
          .then((resolveData: any) => {
            if (subject === '') {
              setDatalist(resolveData);
            } else {
              const refinedData = resolveData.filter((el: any) => {
                return el.subject === subject;
              });
              setDatalist(refinedData);
            }
          })

          .catch((err: Error) => console.error(err))
          .finally(() => {
            setLoading(false);
            setPage(1);
          });
      }
    }
  }, [subject, since, isLoading]);

  useEffect(() => {
    async function getData() {
      return await new Promise((resolve, reject) => {
        resolve(coreData.coreData);
        reject(new Error('No data'));
      });
    }
    if (isLoading) {
      getData()
        .then((resolveData: any) => {
          setCorelist(resolveData);
        })
        .catch((err: Error) => console.error(err))
        .finally(() => {
          setLoading(false);
        });
    }
  }, [isLoading]);

  useEffect(() => {
    async function dataFetch() {
      const pagingData: any[] = [];
      for (let i = 0; i < indexArray.length; i++) {
        if (datalist && datalist[pageIndex[i]] === undefined) {
          break;
        } else {
          pagingData.push(datalist[pageIndex[i]]);
        }
      }
      setPagingDataSet(pagingData);
    }
    if (datalist.length !== 0) {
      dataFetch();
    }
  }, [page, datalist.length, datalist, indexArray.length]);

  const InfiniteLoopSlider: React.FC<InfiniteLoopPropType> = ({
    onHoverStop,
  }) => {
    // 슬라이더에 마우스를 올렸을 때 이벤트
    const onMouseOverSlider = (e: React.MouseEvent) => {
      const slider = e.currentTarget;
      if (e.target === slider) return;
      if (!(slider instanceof HTMLDivElement)) return;
      slider.style.animationPlayState = 'paused';
    };

    // 마우스가 슬라이더에서 벗어났을 때 이벤트
    const onMouseOutSlider = (e: React.MouseEvent) => {
      const slider = e.currentTarget;
      if (!(slider instanceof HTMLDivElement)) return;
      slider.style.animationPlayState = 'running';
    };

    return (
      <div className="InfiniteLoop-slider">
        <div
          className={`InfiniteLoop-inner`}
          onMouseOver={onHoverStop ? onMouseOverSlider : undefined}
          onMouseOut={onHoverStop ? onMouseOutSlider : undefined}
        >
          {corelist.map((el, idx) => (
            <div className="InfiniteLoop-inner-item" key={`core${idx}`}>
              <div className={`InfiniteLoop-inner-item-img-${idx}`} />
              <span>{el}</span>
            </div>
          ))}
          {corelist.map((el, idx) => (
            <div className="InfiniteLoop-inner-item" key={`core${idx}`}>
              <div className={`InfiniteLoop-inner-item-img-${idx}`} />
              <span>{el}</span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleSubject = (e: React.ChangeEvent<any>) => {
    var { id } = e.target;
    setSubject(id);
    setLoading(true);
  };

  const handleSince = (e: React.ChangeEvent<any>) => {
    var { value } = e.target;
    setSince(value);
    setLoading(true);
  };

  const Paging = ({
    activePage,
    itemsCountPerPage,
    totalItemsCount,
    handlePageChange,
    maxItems,
  }: {
    activePage: number;
    itemsCountPerPage: number;
    totalItemsCount: number;
    handlePageChange: any;
    maxItems: number;
  }) => {
    const totalLength =
      Math.ceil(totalItemsCount / itemsCountPerPage) < 1
        ? 1
        : Math.ceil(totalItemsCount / itemsCountPerPage);

    const pagingArray = Array.from(
      {
        length: totalLength,
      },
      (item, index) => index
    );

    const [listNum, setListNum] = useState(1);
    useEffect(() => {
      setListNum(Math.floor((activePage + maxItems - 1) / maxItems));
    }, [activePage, maxItems]);

    // 한 묶음에 보여줄 페이지 넘버
    const listNumIndex =
      listNum === 1
        ? Array.from(
            {
              length: maxItems,
            },
            (item, index) => {
              return index;
            }
          )
        : Array.from(
            {
              length: maxItems,
            },
            (item, index) => {
              return index + maxItems * (listNum - 1);
            }
          );

    return (
      <ul>
        <li
          onClick={() => {
            if (activePage !== 1) {
              handlePageChange(activePage - 1);
            }
          }}
          style={{ color: activePage === 1 ? '#e9e9e9' : `` }}
        >
          <FaArrowLeftLong />
        </li>
        {pagingArray.map((item, index) => {
          if (
            listNumIndex[0] <= index &&
            index <= listNumIndex[listNumIndex.length - 1]
          ) {
            if (index + 1 === activePage) {
              return (
                <li
                  key={item}
                  id={(index + 1).toString()}
                  className="activePage"
                  onClick={() => {
                    handlePageChange(index + 1);
                  }}
                >
                  {index + 1}
                </li>
              );
            } else {
              return (
                <li
                  key={item}
                  id={(index + 1).toString()}
                  onClick={() => {
                    handlePageChange(index + 1);
                  }}
                >
                  {index + 1}
                </li>
              );
            }
          } else return '';
        })}
        <li
          onClick={() => {
            if (activePage !== pagingArray.length) {
              handlePageChange(activePage + 1);
            }
          }}
          style={{
            color: activePage === pagingArray.length ? '#e9e9e9' : ``,
          }}
        >
          <FaArrowRightLong />
        </li>
      </ul>
    );
  };

  return (
    <div className="div-section">
      <div className="div-section1">
        <h1>
          Experience<span style={{ color: '#E69147' }}>.</span>
        </h1>
        <h2>
          <span style={{ color: '#E69147' }}>어라운드</span>는 호기심을 가지고,
          즐겁게 일하는 사람들과 함께합니다.
        </h2>
      </div>
      <div className="div-section2">
        <InfiniteLoopSlider onHoverStop={true}></InfiniteLoopSlider>
      </div>
      <div className="div-section3">
        <div className="div-section3-nav">
          <button id="" onClick={handleSubject}>
            All
          </button>
          <button id="Education" onClick={handleSubject}>
            Education
          </button>
          <button id="Action" onClick={handleSubject}>
            Action
          </button>
          <button id="Design" onClick={handleSubject}>
            Design
          </button>
          <button id="SI" onClick={handleSubject}>
            SI
          </button>
          <button id="etc" onClick={handleSubject}>
            etc
          </button>
          <select onChange={handleSince}>
            <option value="">전체</option>
            <option value="2024">2024</option>
            <option value="2023">2023</option>
            <option value="2022">2022</option>
            <option value="2021">2021</option>
            <option value="2020">2020</option>
            <option value="2019">2019</option>
            <option value="2018">2018</option>
          </select>
        </div>
        {isLoading ? (
          <p>Loading</p>
        ) : (
          <div className="div-section3-nav-table">
            <table>
              <tbody>
                {pagingDataSet.map((el: any, index: number) => {
                  return (
                    <tr key={`experience${index}`}>
                      <td>{el.since}</td>
                      <td style={{ minWidth: '150px' }}>{el.subject}</td>
                      <td style={{ textAlign: 'left' }}>
                        {el.since === 2024 && <span>[진행중]</span>}
                        {el.name}
                      </td>
                      <td>{el.client}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        <div className="div-section3-nav">
          <Paging
            activePage={page}
            itemsCountPerPage={10}
            totalItemsCount={datalist?.length}
            handlePageChange={handlePageChange}
            maxItems={10}
          />
        </div>
      </div>
    </div>
  );
}
