import { useEffect, useState } from 'react';
import toonData from '../../Data/toon.json';
export function Careers() {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [datalist, setDatalist] = useState<any[]>([]);
  useEffect(() => {
    async function getData() {
      return await new Promise((resolve, reject) => {
        resolve(toonData.toonData);
        reject(new Error('No data'));
      });
    }
    if (isLoading) {
      getData()
        .then((resolveData: any) => {
          setDatalist(resolveData);
        })

        .catch((err: Error) => console.error(err))
        .finally(() => {
          setLoading(false);
        });
    }
  }, [isLoading]);

  return (
    <div className="div-section">
      <div className="div-section1">
        <h1>
          Careers<span style={{ color: '#E69147' }}>.</span>
        </h1>
        <h2>
          호기심을 가지고, 즐겁게 일하는 사람들과 함께 만들어가는{' '}
          <span style={{ color: '#E69147' }}>어라운더</span>들의 이야기
        </h2>
      </div>
      <div className="div-section3-grid">
        {datalist.map((el, idx) => (
          <a href={el.url} style={{ animationDelay: `${0.5 + 0.1 * idx}s` }}>
            <img src={el.img} alt=""></img>
          </a>
        ))}
      </div>
    </div>
  );
}
