import '../Style.css';
import {
  FaPaperPlane,
  FaCube,
  FaPeopleGroup,
  FaChartLine,
  FaArrowDown,
  FaRegGem,
  FaRegThumbsUp,
  FaRegStar,
} from 'react-icons/fa6';
import { useState, useRef } from 'react';

export function Branding() {
  const [isBtn, setBtn] = useState('value');
  const scrollRef = useRef<any>();
  const onClickbtn = (e: React.ChangeEvent<any>) => {
    var { id } = e.target;
    setBtn(id);
    scrollRef.current.scrollTop = 600;
  };
  return (
    <div className="div-section" ref={scrollRef}>
      <div className="div-section1">
        <h1>
          Branding<span style={{ color: '#E69147' }}>.</span>
        </h1>
        <h2>
          교육 . 기획 . 디자인{' '}
          <span style={{ color: '#E69147', fontSize: '0.6em' }}>그리고</span>{' '}
          S.I
        </h2>
        <h3>나아가 사람에 가치를 더하다.</h3>
      </div>
      <div className="div-section2">
        <div className="div-section2-btn-box">
          <button id="value" onClick={onClickbtn}>
            <h2>Value</h2>
            <h1>어라운드의</h1>
            <h1>키워드</h1>
            <p>
              <FaArrowDown />
            </p>
          </button>
          <button id="mission" onClick={onClickbtn}>
            <h2>Mission</h2>
            <h1>어라운드의</h1>
            <h1>미션</h1>
            <p>
              <FaArrowDown />
            </p>
          </button>
          <button id="vision" onClick={onClickbtn}>
            <h2>Vision</h2>
            <h1>어라운드의</h1>
            <h1>비전</h1>
            <p>
              <FaArrowDown />
            </p>
          </button>
          <button id="history" onClick={onClickbtn}>
            <h2>History</h2>
            <h1>어라운드의</h1>
            <h1>이야기</h1>
            <p>
              <FaArrowDown />
            </p>
          </button>
          <button id="space" onClick={onClickbtn}>
            <h2>Space</h2>
            <h1>어라운드의</h1>
            <h1>공간</h1>
            <p>
              <FaArrowDown />
            </p>
          </button>
        </div>
      </div>

      {isBtn === 'value' && (
        <div className="div-section3-value">
          <h1>어라운드를 만드는 핵심키워드는 </h1>
          <h3>무엇일까요? </h3>
          <div className="div-section3-box">
            <div className="div-section3-box-div">
              <h5>
                어라운드는 즐거움과 호기심을 기반으로 사람들의 성장을 지원하는
                것을 핵심 가치로 삼고 있습니다.
              </h5>
              <h5>
                우리는 단순히 비즈니스를 하는 것을 넘어서, 사람들이 일상에서
                즐거움을 느끼고 호기심을 키우며, 그 과정에서 성장할 수 있는
                기회를 제공하고자 합니다.
              </h5>

              <h4>즐거움은 우리가 하는 일에 창의를 더하는 요소입니다. </h4>
              <h5>
                우리는 업무를 더욱 유익하고 흥미롭게 만들기 위해 창의적이고
                재미있는 방법을 적극적으로 도입하고, 직원들이 업무를 즐길 수
                있는 환경을 조성합니다.
              </h5>
              <h4>호기심은 우리의 엔진입니다. </h4>
              <h5>
                우리는 항상 새로운 아이디어를 탐구하고, 기존 방식에 도전하여 더
                나은 해결책을 찾습니다. 우리는 직원들이 자신의 호기심을 키우고,
                새로운 지식과 기술을 습득할 수 있도록 지원합니다.
              </h5>
              <h4>사람은 우리의 최우선입니다. </h4>
              <h5>
                우리는 고객들의 다양한 요구와 필요에 부응하기 위해 최선을
                다하며, 그들의 만족을 위해 끊임없이 노력합니다. 사람의 소중함을
                알고 직원들이 서로를 존중하고 협력하는 문화를 육성하여, 함께
                성장하는 가치를 추구합니다.
              </h5>
              <h4>성장은 우리의 목표입니다. </h4>
              <h5>
                우리는 개인과 조직의 지속적인 발전을 위해 지원하고, 학습과
                발전을 촉진하는 환경을 조성합니다. 우리는 변화와 도전을
                두려워하지 않으며, 실패를 통해 배우고 성장하는 문화를
                지향합니다.
              </h5>
              <h4>
                어라운드는 이러한 가치를 바탕으로 산업을 선도하고, 사회적으로도
                기여하는 성장하는 기업으로서의 역할을 톡톡히 해내고 있습니다.
              </h4>
            </div>
            <div className="div-section3-box-div">
              <div className="box">
                <div className="front"></div>
                <div className="back"></div>
                <div className="right"></div>
                <div className="left"></div>
                <div className="top"></div>
                <div className="bottom"></div>
              </div>
            </div>
          </div>
          <div className="div-section3-box">
            <div className="box-sup">
              <div className="box-sup-icon">
                <FaPaperPlane />
              </div>
              <div className="box-sup-box">
                <h2>즐거움 [amusement]</h2>
              </div>
            </div>
            <div className="box-sup">
              <div className="box-sup-icon">
                <FaCube />
              </div>
              <div className="box-sup-box">
                <h2>호기심 [curiosity]</h2>
              </div>
            </div>
            <div className="box-sup">
              <div className="box-sup-icon">
                <FaPeopleGroup />
              </div>
              <div className="box-sup-box">
                <h2>사람 [human]</h2>
              </div>
            </div>
            <div className="box-sup">
              <div className="box-sup-icon">
                <FaChartLine />
              </div>
              <div className="box-sup-box">
                <h2>성장 [revenue]</h2>
              </div>
            </div>
          </div>
          <div className="div-section3-box">
            <div className="div-video">
              <iframe
                width="100%"
                height="100%"
                src="https://testsbucket.s3.ap-northeast-2.amazonaws.com/around_video2.mp4"
                title="어라운드 홍보영상"
              ></iframe>
            </div>
            <div className="div-video">
              <iframe
                width="100%"
                height="100%"
                src="https://testsbucket.s3.ap-northeast-2.amazonaws.com/around_video.mp4"
                title="어라운드 홍보영상"
              ></iframe>
            </div>
          </div>
        </div>
      )}
      {isBtn === 'mission' && (
        <div className="div-section3-mission">
          <h1>make AROUNDful!</h1>
          <h3>어라운드스럽게!</h3>
          <div className="div-section3-box">
            <div className="div-section3-box-div">
              <h5>어라운드는 아름다움, 깔끔함, 그리고 새로움으로 일합니다.</h5>
              <h5>
                청소년 진로프로그램,마인드업 프로그램, 단체/기업 맞춤교육 각 종
                기획행사 및 디자인, 웹사이트 개발, 웹 기반의 온라인컨텐츠
                제작까지. 아름다움을 더하는 디자인, 새로운 컨텐츠 개발, 깔끔한
                기획을 선사합니다.
              </h5>

              <h4>
                어라운드는 항상{' '}
                <span
                  style={{ fontWeight: '900', color: '#fff', margin: ' 0 1%' }}
                >
                  [ 새로움 ]
                </span>
                을 추구합니다.
              </h4>
              <h5>
                우리는 변화의 주도자이며, 새로운 아이디어와 혁신을 통해 선도적인
                위치를 유지합니다. 우리는 산업과 시장의 변화에 민첩하게
                대응하고, 고객들에게 더 나은 가치를 제공하기 위해 지속적으로
                발전합니다.
              </h5>
              <h4>
                어라운드는{' '}
                <span
                  style={{ fontWeight: '900', color: '#fff', margin: ' 0 1%' }}
                >
                  [ 깔끔함 ]
                </span>
                을 추구합니다.{' '}
              </h4>
              <h5>
                우리의 기획과 컨텐츠는 항상 사용하기 편리하고 깔끔하게 정돈되어
                있습니다. 효율적이고 체계적인 프로세스를 통해 고객들에게
                깔끔하고 일관된 경험을 제공하며, 이를 통해 신뢰와 만족을
                증대시킵니다.
              </h5>
              <h4>
                어라운드는{' '}
                <span
                  style={{ fontWeight: '900', color: '#fff', margin: ' 0 1%' }}
                >
                  [ 아름다움 ]
                </span>
                을 추구합니다.{' '}
              </h4>
              <h5>
                우아하고 아름다운 디자인과 아티스틱한 요소를 통해 고객들에게
                시각적으로 아름다운 경험을 제공합니다. 제품, 서비스, 그리고
                브랜드 전반에 걸쳐 아름다움을 실현함으로써 우리의 가치를
                전달하고자 합니다.
              </h5>
              <h4 style={{ fontWeight: '900', color: '#fff' }}>
                {'<'} 어라운드스러움 {'>'}
              </h4>
              <h5>
                아름다움, 깔끔함, 그리고 새로움이라는 가치를 바탕으로, 우리는
                고객들에게 뛰어난 경험을 제공하고, 지속적인 혁신과 발전을
                이끌어내며, 사회적 가치를 창출하는 글로벌 리더가 되고자 합니다.
                함께 우리의 여정에 동참하여 즐거움과 호기심, 성장을 함께
                누려보시기 바랍니다.
              </h5>
            </div>
            <div className="div-section3-box-div">
              <div className="box">
                <div className="front"></div>
                <div className="back"></div>
                <div className="right"></div>
                <div className="left"></div>
                <div className="top"></div>
                <div className="bottom"></div>
              </div>
            </div>
          </div>
          <div className="div-section3-box">
            <div className="box-sup">
              <div className="box-sup-icon">
                <FaRegStar />
              </div>
              <div className="box-sup-box">
                <h2>새로움 [newness]</h2>
              </div>
            </div>
            <div className="box-sup">
              <div className="box-sup-icon">
                <FaRegThumbsUp />
              </div>
              <div className="box-sup-box">
                <h2>깔끔함 [neatness]</h2>
              </div>
            </div>
            <div className="box-sup">
              <div className="box-sup-icon">
                <FaRegGem />
              </div>
              <div className="box-sup-box">
                <h2>아름다움 [beautiful]</h2>
              </div>
            </div>
          </div>
        </div>
      )}
      {isBtn === 'vision' && (
        <div className="div-section3-vision">
          <h1>어라운드의 미래</h1>
          <h3>2027년의 어라운드의 모습</h3>
          <div className="div-section3-box">
            <div className="div-section3-box-div-visions">
              <h5>2027년, 어라운드는 이러한 미래를 그리고 있습니다.</h5>
              <div>
                <span></span>
                <p>
                  <span className="div-section3-box-div-visions-text">
                    2024
                  </span>
                </p>
                <span></span>
                <p>
                  <span className="div-section3-box-div-visions-text">
                    2026
                  </span>
                </p>
                <span></span>
                <p>
                  <span className="div-section3-box-div-visions-text">
                    2028
                  </span>
                </p>
                <span></span>
                <b>
                  <span style={{ color: '#E52D0E' }}>IPO</span>
                  2030
                </b>
              </div>

              <h2 style={{ margin: '3% 0 2% 0' }}>
                <span>구성원 </span>77명
              </h2>
              <h2 style={{ margin: '1% 0 5% 0' }}>
                <span>매출액 </span>117억 원
              </h2>
              <h5>회사와 같이 지속적으로 성장하는 어라운더들을 통해</h5>
              <h5>어라운드는 더 커질 것 입니다.</h5>
            </div>
            <div className="div-section3-box-div">
              <div className="div-section3-box-div-vision" />
            </div>
          </div>
        </div>
      )}
      {isBtn === 'history' && (
        <div className="div-section3-history">
          <h1>어라운드의 브랜드 히스토리</h1>
          <h3>현재의 어라운드의 모습</h3>
          <div>
            <div>
              <b style={{ justifyContent: 'end' }}>• 어라운드 법인설립</b>
              <p>2017</p>
              <br></br>
            </div>
            <div>
              <br></br>
              <p>2018~2019</p>
              <b>
                • 출판사/인쇄사 등록 <br />• 온라인 외국어 학원 설립
              </b>
            </div>
            <div>
              <b style={{ justifyContent: 'end' }}>
                • 예비사회적기업인 지정 <br />• 대전 청년공간 운영위탁
                <br />
                • 대청넷 사무국 운영위탁
                <br />• 대전시 청년활동 유공자 표창 <br />• 유성구청 유공자 표창
              </b>
              <p>2020</p>
              <br></br>
            </div>
            <div>
              <br></br>
              <p>2021</p>
              <b>
                • 온라인 언론사 등록
                <br />
                • 비디오물 제작업 업종 등록
                <br />
                • 사회적 기업주간행사 운영
                <br />
                • 대전청년공간 "청춘인포" 위탁운영
                <br />
              </b>
            </div>
            <div>
              <b style={{ justifyContent: 'end' }}>
                • 대전청년내일센터 위탁운영
                <br />
                • 지자체 청소년 정책 실무자 교육
                <br />
                • 스포츠인권의 날 행사 운영
                <br />
                • 국립중앙과학관 온라인특별전시 홍보
                <br />
              </b>
              <p>2022</p>
              <br></br>
            </div>
            <div>
              <br></br>
              <p>2023</p>
              <b>
                • 대덕특구 50주년 대전과학현장탐방 운영
                <br />
                • 대한당구연맹 i-리그 홍보
                <br />
                • 대전광역시 보육교직원 힐링연수
                <br />
              </b>
            </div>
          </div>
        </div>
      )}
      {isBtn === 'space' && (
        <div className="div-section3-space">
          <h1>업무와 편안함이 조화로운</h1>
          <h2>어라운드의 공간들</h2>
          <div className="div-section3-box-space">
            <div className="div-section3-box-div-space">
              <h3>
                업무와 여가를 결합한 새로운 근무 문화,
                <span
                  style={{
                    fontWeight: '900',
                    color: '#1c3663',
                    fontSize: '1.5em',
                  }}
                >
                  {' '}
                  오픈 스페이스 펍
                </span>
              </h3>
              <h4>
                <span>위치</span>
                대전광역시 유성구 농대로 2번길 23, 1층
              </h4>
              <h4>
                <span>소개</span>
              </h4>
              <h5>
                편안하고 트렌디한 분위기에서 업무를 보다 즐겁게 할 수 있도록
                디자인된 이 공간은 <br />
                펍과 회의 공간을 융합시킨 우리들의 공간입니다.
              </h5>
              <h5>
                창문으로 햇빛이 잘 들어와 환하고 쾌적한 분위기, 편안한 소파와
                의자가 배치된 휴게공간과 전문적인 비즈니스 모임을 위한 회의실이
                조화를 이루며, 다양한 업무 활동에 적합한 환경을 제공합니다.
              </h5>
              <h5>
                업무 중이나 휴식 시간에 모두 즐길 수 있는 다양한 종류의 고품질의
                커피와 차, 알코올 음료까지 다양한 음료 제공합니다.
              </h5>
            </div>
            <div className="div-section3-box-div">
              <div className="div-section3-space-img-1" />
              <div className="div-section3-space-img-0" />
            </div>
          </div>

          <div className="div-section3-box-space">
            <div className="div-section3-box-div-space">
              <h3>
                자연의 아름다움이 오피스로 초대하는 공간,
                <span
                  style={{
                    fontWeight: '900',
                    color: '#1c3663',
                    fontSize: '1.5em',
                  }}
                >
                  {' '}
                  싼토리니
                </span>
              </h3>
              <h4>
                <span>위치</span>
                대전광역시 유성구 대학로 197 3층
              </h4>
              <h4>
                <span>소개</span>
              </h4>
              <h5>
                그리스의 산토리니를 배경으로 인테리어한 화이트 컬러의
                사무실입니다. 도심 속에서도 자연과 조화를 이루며 휴식과 창의성을
                즐길 수 있는 우리들의 오피스 공간입니다.
              </h5>
              <h5>
                넓은 창문을 통해 집중력을 높이고 안정감을 주는 갑천과 유림공원의
                아름다운 뷰를 제공합니다.
              </h5>
              <h5>
                모던하고 심플한 인테리어로 조성, 편안하고 기능적인 업무 환경을
                제공합니다.
              </h5>
            </div>
            <div className="div-section3-box-div">
              <div className="div-section3-space-img-3" />
              <div className="div-section3-space-img-2" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
