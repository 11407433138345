import { Link } from 'react-router-dom';
import './App.css';
import { Router } from './Route';

export function App() {
  return (
    <div className="scene">
      <div className="scene-img">
        <div className="scene-img-item" />
        <div className="scene-img-item" />
        <div className="scene-img-item" />
        <div className="scene-img-item" />
        <div className="scene-img-item" />
        <div className="scene-img-item" />
        <div className="scene-img-item" />
        <div className="scene-img-item" />
        <div className="scene-img-item" />
        <div className="scene-img-item" />
        <div className="scene-img-item" />
      </div>
      <div className="wrap">
        <header className="header">
          <Link to={'/'} className="logo" />
          <div className="navigator">
            <Link to="/branding" className="nav">
              Branding
            </Link>
            <Link to="/experience" className="nav">
              Experience
            </Link>
            <Link to="/business" className="nav">
              Business
            </Link>
            <Link to="/careers" className="nav">
              Careers
            </Link>
          </div>
        </header>

        <div className="main">
          <Router />
        </div>

        <footer className="footer">
          <div className="inner">
            <p>
              <span>
                <strong>ADD : </strong>
                대전광역시 유성구 농대로 2번길 23, 1층
              </span>
              <span>
                <strong>TEl : </strong>
                042-551-5974
              </span>
            </p>
            <p>
              <span>
                <strong>
                  Copyrightⓒ 2024 (주)어라운드. All Rights Reserved.
                </strong>
              </span>
              <span>사업자 번호 : 296-87-00825</span>
            </p>
          </div>
        </footer>
      </div>
    </div>
  );
}
