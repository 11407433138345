import { FaGithub, FaSquareInstagram } from 'react-icons/fa6';
import axios from 'axios';
import { useEffect, useState } from 'react';
export interface InstagramContent {
  id: string;
  caption: string;
  media_type: string;
  media_url: string;
  permalink: string;
  username: string;
}

export function Business() {
  const [designData, setDesignData] = useState<InstagramContent[]>([]);
  const eduData: string[] = ['edu0', 'edu1', 'edu2', 'edu3', 'edu4', 'edu5'];
  const actData: string[] = ['act0', 'act1', 'act2', 'act3', 'act4', 'act5'];
  const siData = [
    {
      img: 'si0',
      url: 'https://main.dq5rhbdlu59ai.amplifyapp.com/',
    },
    {
      img: 'si1',
      url: 'https://대덕특구과학현장탐방.kr/',
    },
    {
      img: 'si2',
      url: 'https://main.d2kdhbt24jgris.amplifyapp.com/',
    },
    {
      img: 'si3',
      url: 'https://스포츠인권이란.kr/',
    },
    {
      img: 'si4',
      url: 'https://ifthenak.kr/',
    },
    {
      img: 'si5',
      url: 'https://main.d3j8gvahfs31tg.amplifyapp.com/',
    },
  ];

  const [isLoading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    async function getData() {
      await axios
        .get(
          'https://graph.instagram.com/6764892080282100/media?fields=id,media_type,media_url,permalink,thumbnail_url,username,caption&access_token=IGQWRQbC1CYnBGZAzZAaX0VXNGFld29Mc3Q4THpWdWFVX0ExcmFtb01qZAExfN0xETXNrWXAyd1daYXhJQkpkd1N4VjF1MTJId2F4WXRKZADJ0UXFJb196eG5hY0pXVU1peWZAja0NSZAmNjZAFZAXQQZDZD'
        )
        .then((result) => {
          setDesignData(result.data.data);
          console.log(result.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (isLoading) {
      getData().finally(() => {
        setLoading(false);
      });
    }
  }, [isLoading]);

  return (
    <div className="div-section">
      <div className="div-section1">
        <h1>
          Business<span style={{ color: '#E69147' }}>.</span>
        </h1>
        <h2>
          교육 . 기획 . 디자인{' '}
          <span style={{ color: '#E69147', fontSize: '0.6em' }}>그리고</span>{' '}
          S.I
        </h2>
        <h3>가치는 더하는 새로운 시도와 도전</h3>
      </div>

      <div className="div-section2">
        <div className="div-section2-grid">
          <div>
            <h1>
              <span style={{ color: '#E69147', fontSize: '1.2em' }}>E</span>
              ducation
            </h1>
            <h2>: Engaging Education for Youth and Adults</h2>
            <div className="div-section3-box-img">
              {!isLoading &&
                eduData.reverse().map((el, idx) => {
                  return (
                    <img
                      src={`https://testsbucket.s3.ap-northeast-2.amazonaws.com/${el}.png`}
                      alt=""
                    />
                  );
                })}
            </div>
          </div>
          <div>
            <h1>
              <span style={{ color: '#E69147', fontSize: '1.2em' }}>A</span>
              ction
            </h1>
            <h2>: Innovative Ideas, Flawless Execution.</h2>
            <div className="div-section3-box-img">
              {!isLoading &&
                actData.map((el, idx) => {
                  return (
                    <img
                      src={`https://testsbucket.s3.ap-northeast-2.amazonaws.com/${el}.png`}
                      alt=""
                    />
                  );
                })}
            </div>
          </div>
          <div>
            <h1>
              <span style={{ color: '#E69147', fontSize: '1.2em' }}>D</span>
              esign
            </h1>
            <h2>: Creative Design, Unique World</h2>
            <div className="div-section3-box-img">
              {!isLoading &&
                designData.reverse().map((el, idx) => {
                  if (idx < 6) {
                    return (
                      <img
                        src={el.media_url}
                        alt=""
                        onClick={() => window.open(el.permalink)}
                      />
                    );
                  } else {
                    return '';
                  }
                })}
            </div>
            <h3>
              +더보기
              <FaSquareInstagram
                style={{ fontSize: '2em', margin: '0 2%' }}
                onClick={() =>
                  window.open('https://www.instagram.com/aroundesign_/')
                }
              />
            </h3>
          </div>
          <div>
            <h1>
              <span style={{ color: '#E69147', fontSize: '1.2em' }}>S</span>
              .I
            </h1>
            <h2> : Turning Imagination into Online Innovation</h2>
            <div className="div-section3-box-img">
              {!isLoading &&
                siData.map((el, idx) => {
                  return (
                    <img
                      src={`https://testsbucket.s3.ap-northeast-2.amazonaws.com/${el.img}.png`}
                      alt=""
                      onClick={() => window.open(el.url)}
                    />
                  );
                })}
            </div>
            <h3>
              +더보기{' '}
              <FaGithub
                style={{ fontSize: '2em', margin: '0 2%' }}
                onClick={() => window.open('https://github.com/siaroundkorea')}
              />
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
}
