import '../Style.css';

export function Home() {
  return (
    <>
      <div className="main-div1"></div>
      <div className="main-div2">
        <span className="letter">#즐거움</span>
        <span className="letter1">#호기심</span>
        <span className="letter2">#사람</span>
        <div className="container">
          <b />
          <p>
            <span style={{ color: '#1c3663' }}>어라운드</span>는{' '}
          </p>
          <p>
            <span style={{ color: '#1c3663', fontSize: '1.2em' }}>
              교육·기획·디자인{' '}
              <span style={{ color: '#E69147', fontSize: '0.7em' }}>
                그리고
              </span>{' '}
              프로그램 개발
            </span>
            을 통해
          </p>
          <p>즐거움을 전달하는 콘텐츠를 제공하고, </p>
          <p>사람들의 호기심을 자극하고,</p>
          <p>사람과 함께 하는 즐거움을 선사하는</p>
          <p>
            혁신적인{' '}
            <span style={{ color: '#E69147', fontSize: '1.3em' }}>
              종합 기획사
            </span>
            입니다.
          </p>
          <p></p>
        </div>
      </div>
    </>
  );
}
